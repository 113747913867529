* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans 3";
}

.rotate360 {
  animation: rotate 0.6s linear infinite;
}

.visible_loader {
  display: block;
  font-size: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6);
}
.loading_icon {
  background: url(https://cdn.pegasus.imarticus.org/imarticus_2/imarticus_logo_loader.svg)
    center no-repeat;
  display: block;
  font-size: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999;
}

.loaderHeight {
  top: 35vh;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1080px) {
  .loaderHeight {
    top: 35vh;
  }
}
@media (max-width: 800px) {
  .loaderHeight {
    top: 35vh;
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.login_main {
  background: rgba(3, 86, 66, 0.25);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_img_container {
  overflow: hidden;
  width: 570px;
  height: 674px;
  border-radius: 24px;
  background: var(
    --Grad-1,
    linear-gradient(259deg, #006f7e -61.14%, #68c29d 100.65%)
  );
}

.login_img_container_head {
  padding: 40px;
}

.login_form {
  border-radius: 24px;
  background: #fff;
  margin-left: -80px;
}

.login_img_container_header {
  color: #fff;
  font-family: "Source Sans 3";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 44px;
  text-transform: uppercase;
  width: 421px;
  margin-top: 24px;
}

.login_img_container_span {
  color: #fff;
  font-family: "Source Sans 3";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  margin-top: 16px;
  width: 350px;
}

.login_form_container {
  padding: 64px 48px;
  height: 573px;
  width: 600px;
}

.login_form_header {
  color: #212a39;
  font-family: "Source Sans 3";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 100% */
}

.login_form_des {
  color: #212a39;
  font-family: "Source Sans 3";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 177.778% */
  margin-top: 24px;
}

.login_labels {
  color: #212a39;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  margin-top: 24px;
}

.login_inputs {
  display: flex;
  border: solid;
  width: 564px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 5px;
  background: rgba(33, 42, 57, 0.06);
  margin-top: 8px;
  border: none;
  border-bottom: solid;
  border-color: #00a400;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

.login_btn {
  display: flex;
  width: 584px;
  padding: 18px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: #035642;
  border: none;
  margin-top: 29px;
  color: #fff;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}

.login_switch_btn {
  color: #035642;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  text-decoration-line: underline;
}

.login_switch {
  margin: 18px 0px 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.login_adm_btn {
  width: 100%;
  margin-top: 40px;
  text-align: center;
}

.login_switch_adm_btn {
  color: #035642;
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.15);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  text-decoration-line: underline;
  background-color: #fff;
  width: 128px;
  height: 40px;
  cursor: pointer;
}

.login_error {
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  padding: 10px;
  background-color: rgba(255, 0, 0, 0.098);
}

.sidebar {
  width: 250px;
  max-width: 250px;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  background: #055646;
  z-index: 2;
}

.sidelogo {
  margin-top: 1rem;
  margin-left: 1rem;
}

.sidebar_list {
  margin-top: 2rem;
}

.sidebar_list > div {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 91%;
  height: 56px;
  color: #fff;
  padding-left: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidebar_active {
  border-left: solid;
  background: #022a22;
}

.topbar {
  position: fixed;
  z-index: 1;
  top: 0;
  height: 56px;
  width: 100%;
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.main {
  margin-left: 250px;
  margin-top: 56px;
  padding: 40px 80px;
  max-height: 80vh;
  position: relative;

  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.topbar_info {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #212a39;
  margin-left: 300px;
}

.topbar_profile {
  display: flex;
  margin-right: 80px;
  width: 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  height: 100px;
}

.topbar_drop {
  position: absolute;
  top: 42px;
  right: 120px;
  width: 200px;
  border-radius: 8px;
  background: rgb(228, 228, 228);
}

.topbar_drop > div {
  padding: 10px;
  border: solid 1px #f9f9f9;
  cursor: pointer;
}

.topbar_drop > div:hover {
  background-color: #05564609;
  /* border: solid 1px #055646; */
  color: #055646;
}

.topbar_drop2 {
  position: absolute;
  top: 55px;
  right: 100px;
  width: 200px;
  border-radius: 8px;
  background: rgb(228, 228, 228);
  border: none;
}
.topbar_drop2 > div {
  padding: 10px;
  border: solid 1px #f9f9f9;
  cursor: pointer;
}

.topbar_drop2 > div:hover {
  background-color: #05564609;
  /* border: solid 1px #055646; */
  color: #055646;
}

.topbar_pic {
  width: 40px;
}

.filters {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.filters > div {
  width: 20%;
}

.filters-4 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.filters-4 > div {
  width: 13%;
}

.filter_inputs {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #3c4852;
  padding: 10px;
  background-color: #fff;
  color: #000;
}

.filters-2 {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.w-110 {
  width: 110%;
}

.filters-2 > div {
  width: 30%;
}

.filter-3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.filter-3 > div {
  width: 17%;
  margin: 1rem;
}

.filter_headers {
  overflow: hidden;
  color: rgba(33, 42, 57, 0.75);
  text-align: left;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.partner_leads_header {
  color: #212a39;
  margin: 10px 0px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: space-between;
}

.application_header {
  color: #212a39;
  font-family: "Source Sans 3";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tabs_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
      0deg,
      rgba(33, 42, 57, 0.15) 0%,
      rgba(33, 42, 57, 0.15) 100%
    ),
    #fff;

  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 48px;
  border-radius: 8px;
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 253px;
  height: 48px;
  cursor: pointer;
  color: #3c4852;
  text-align: center;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.304px;
}

.active_tab {
  border-radius: 8px;
  background: #055646;
  color: #fff;
}

.fillup_form {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}

.fillup_form_field {
  padding: 1rem 0;
}

.fillup_form_field > div {
  font-size: 14px;
}

.fillup_form_input_head {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.fillup_form_inputs {
  border-radius: 8px;
  border: 1px solid rgba(33, 42, 57, 0.25);
  padding: 1rem;
}

.h-100 {
  height: 100px;
}

.addBlock {
  background-color: #efefef;
  margin-left: 0.5rem;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 12px;
}

.w30 {
  width: 30% !important;
}

.back_btn {
  border: none;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border-radius: 8px;
}

.col-green {
  background-color: #055646;
  color: white;
}

.col-green-reverse {
  color: #055646;
  background-color: white;
  border: solid 1px;
}

.v_application {
  min-height: 100vh;
  background: var(
    --Background-of-Forms,
    linear-gradient(245deg, #178990 2.97%, #0d4b6f 100%)
  );

  padding-bottom: 10vh;
}

.v_application_topbar {
  height: 80px;
  fill: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.v_application_topbar > img {
  margin-left: 135px;
}

.v_application_main {
  min-height: 70vh;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
}

.v_a_back {
  color: #fff;
  font-family: "Source Sans 3";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  margin-left: 1rem;
}

.v_a_header {
  border-top: solid 6px;
  border-radius: 8px;
  border-color: #47c3cf;
  background: #fff;
  padding: 24px 40px;
  margin-top: 1rem;
}

.v_a_percent {
  border: 2px solid;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v_a_per {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v_a_header1 {
  color: #3c4852;
  opacity: 0.5;
  font-family: "Source Sans 3";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}

.v_a_header2 {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  text-transform: capitalize;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.v_a_header3 {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 0.5rem;
}

.v_topnav {
  margin-top: 1rem;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.v_topnav > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0px;
  height: 68px;
  /* cursor: pointer; */
}

.v_t_3 {
  background-color: #badce5;
}

.v_t_1 {
  background: #e8f4f7;
}

.v_t_2 {
  background-color: #d0e7ee;
}

.v_a_main_form {
  background-color: #fff;
  margin-top: 1rem;
  min-height: 5vh;
  border-top: solid 6px;
  border-radius: 8px;
  border-color: #47c3cf;
}

.final_form {
  width: 50%;
  margin: 1rem auto;
  border-top: solid 1px;
  padding: 1rem;
}

.fblock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #efefef6f;
  padding: 1rem;
  border-bottom: solid 1px #055646;
  margin-top: 1rem;
}

.fblock > img {
  cursor: pointer;
}

.table_edit {
  cursor: pointer;
  padding: 1px;
  font-size: 12px;
  background-color: #efefef;
  border-radius: 4px;
  margin: 5px;
  border: none;
}

.table_view {
  background-color: #055646;
  color: #fff;
}

.rjsf {
  /* border: solid; */
  padding: 0.5rem;
}

legend {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  margin-bottom: 1rem;
}

fieldset {
  border: none;
  /* border: solid; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.rjsf_field {
  width: 50%;
  margin-bottom: 24px;
}

.rjsf_field2 {
  width: 100%;
}

.rjsf_field > label {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: block;
}

.rjsf_field > input,
.rjsf_field > select {
  border-radius: 8px;
  border: 1px solid rgba(33, 42, 57, 0.25);
  width: 90%;
  min-height: 28px;
  padding: 10px;
  background-color: #fff;
}

.rjsf_field2 > input {
  width: 95%;
}

.rjsf_field_pic_value {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.rjsf_field_pic_value > div {
  display: none;
}

.rjsf_field_pic > input[type="file"] {
  border: solid;
}

.rjsf_field_pic_value > label {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.rjsf_field_pic_value > input {
  border-radius: 8px;
  border: 1px solid rgba(33, 42, 57, 0.25);
  width: 60%;
  min-height: 28px;
  padding: 10px;
  background-color: #fff;
}

.rjsf_field > select {
  width: 93%;
  height: 48px;
}

.rjsf_field > div > input[type="file"] {
  border-radius: 8px;
  padding: 0.85rem 1rem;
  width: 100%;
  background-color: #efefef;
}

.form_navigation {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px;
  border-color: rgba(128, 128, 128, 0.573);
}

.form_back,
.form_next {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid #035642;
  background-color: #fff;
  color: #035642;
  text-align: center;
  font-family: "Source Sans 3";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.form_next {
  background: var(--035642, #035642);
  color: #fff;
}

.popupModal {
  background-color: #0000003b;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.popupModal_main {
  width: 60%;
  background-color: #fff;
  border-radius: 8px;
}

.popupModal_main_2 {
  width: 30%;
  background-color: #fff;
  border-radius: 8px;
}

.popupModal_header {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}

.popupModal_header > span {
  color: #212a39;
  font-family: "Source Sans 3";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.popupModal_body {
  width: 100%;
}

.popupModal_form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.popupModal_form_2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.popupModal_fields {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 45%;
  padding: 10px;
}

.popupModal_fields {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 45%;
  padding: 10px;
}

.popupModal_fields-2 {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 10px;
}

.popupModal_fields-3 {
  width: 100%;
  display: flex;
  padding: 10px 3%;
}

.eye2 {
  width: 25px;
  border: solid 1px grey;
  cursor: pointer;
  border-radius: 8px;
  padding: 0.3rem;
}

.eye_div {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.popupModal_label {
  color: #212a39;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.popupModal_input {
  border-radius: 3px;
  border: 1px solid rgba(33, 42, 57, 0.25);
  padding: 0.7rem 1rem;
  background-color: #fff;
}

.popupModal_ccod {
  border: 1px solid rgba(33, 42, 57, 0.25);
  background-color: #efefef;
}

.mob_input {
  border: none;
  width: 66%;
}

.ccod_input {
  width: 25%;
  padding: 0.65rem 0.1rem;
  border: none;
}

.popupModal_foot {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  border-top: 1px solid rgba(33, 42, 57, 0.25);
  margin-top: 1rem;
  /* width: 100%; */
}

.pm_create {
  border-radius: 4px;
  background: #014644;
  width: 74px;
  height: 32px;
  flex-shrink: 0;
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 5px;
}

.pm_cancel {
  background: #dc3545;
}

*:disabled {
  background: #efefef;
}

.timer {
  margin-top: -21px;
  font-size: 14px;
  text-align: right;
}

.resendOtpBtn {
  background-color: #fff;
  cursor: pointer;
  border: none;
  text-decoration: underline;
}

.error-detail,
.error-detail > li {
  display: none;
}

.v_a_infos {
  margin-top: 1rem;
}

.v_a_status {
  color: #3c485280;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.v_a_status_red {
  color: #ff3a44;
}

.v_a_status_green {
  color: #67c15e;
}

.progress_bar {
  width: 91%;
  height: 8px; /* Adjust height as needed */
  border-radius: 8px; /* Border radius */
  overflow: hidden; /* Ensure border radius is applied to the progress bar */
  margin-left: 4rem;
}

.progress_bar::-webkit-progress-bar {
  background-color: #3c485214; /* Unfilled background color */
}

.progress_bar::-webkit-progress-value {
  background-color: #67c15e; /* Filled background color */
}

.point {
  width: 20px; /* Adjust size of the outer dot */
  height: 20px;
  border-radius: 50%; /* Make it a circle */
  background-color: white; /* Outside dot color */
  position: relative;
  border: solid #efefef;
}

.inner-dot {
  width: 10px; /* Adjust size of the inner dot */
  height: 10px;
  background-color: #67c15e; /* Inside dot color */
  border-radius: 50%; /* Make it a circle */
  position: absolute;
  top: 50%; /* Position it in the center */
  left: 50%;
  transform: translate(-50%, -50%); /* Center it */
}

.inner-dot-2 {
  width: 10px; /* Adjust size of the inner dot */
  height: 10px;
  background-color: red; /* Inside dot color */
  border-radius: 50%; /* Make it a circle */
  position: absolute;
  top: 50%; /* Position it in the center */
  left: 50%;
  transform: translate(-50%, -50%); /* Center it */
}

.progress_bar_checkpoint {
  display: flex;
  justify-content: space-between;
  margin-top: -1.2rem;
}

.pointer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.pointer_sub_head {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}
.w-73 {
  width: 73%;
}

.w-50 {
  width: 50%;
}

.allflags {
  padding-left: 1rem;
}

.flag_blocks {
  background-color: #efefef;
  padding: 0.5rem;
  margin: 0.5rem;
  cursor: pointer;
}

.optionBtns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.optBtn {
  display: flex;
  justify-content: center;
  height: 56px;
  padding: 0px 29px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  border: solid 1px #035642;
  background-color: #fff;
  color: #035642;
  border-radius: 8px;
  width: 30%;
}

.activeOtpBtn {
  background-color: #03564226;
}

.finallSubmitAdminbtn {
  text-align: right;
}

.w-60 {
  max-width: 60%;
}

.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.mb-3 {
  margin-bottom: 3rem;
}

.w-78 {
  width: 78%;
}

.uploadPic {
  width: 61.5%;
}

.customUpload {
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed rgba(60, 72, 82, 0.4);
  background: rgba(9, 114, 82, 0.1);
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
}

/* .customUpload:hover {
  background-color: #45a049;
} */

.fileName {
  margin-top: 10px;
}

.fileRemove {
  margin-top: 10px;
  background-color: #f44336; /* Red */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.uploadFiletoAws {
  background-color: #055646;
}

.smallText_uploadfile {
  color: rgba(60, 72, 82, 0.75);
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
}

.uploadtext-1 {
  color: var(--097252, #097252);
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.uploadtext-2 {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.checkbox > label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
}

#root_Declare {
  width: 100px;
  border: solid;
}

.checkbox > label > span {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="file"]:read-only {
  background-color: #fff;
}

.rjsf_field_radios {
  width: 100%;
}

.field-radio-group {
  display: flex;
  width: 25%;
  justify-content: space-between;
}

.radio {
  cursor: pointer;
}

.radio > label > span > span {
  margin-left: 10px;
  color: #212a39;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.red {
  color: #dc3545;
}

.radio-group {
  display: flex;
  gap: 40px;
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-radio input[type="radio"] {
  display: none;
}

.custom-radio .radio-btn {
  width: 20px;
  height: 20px;
  border: 2px solid #014644;
  border-radius: 50%;
  position: relative;
  margin-right: 5px;
}

.custom-radio .radio-btn::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #014644;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

.custom-radio input[type="radio"]:checked + .radio-btn::after {
  transform: translate(-50%, -50%) scale(1);
}

.plus {
  font-size: 1.3rem;
  margin-left: 1rem;
}

.no_application {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.na_head {
  color: #212a39;
  text-align: center;
  font-family: "Source Sans 3";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.na_desc {
  color: #212a39;
  text-align: center;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search_filters {
  border-radius: 4px;
  border: 1px solid rgba(60, 72, 82, 0.5);
  opacity: 0.75;
  width: 174px;
  height: 40px;
  flex-shrink: 0;
  background-color: #fff;
}

.search_filters > option {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.search_newapp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ph-1 {
  padding: 0rem 1rem;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.head_bars {
  display: flex;
  justify-content: space-between;
}

.breadcrumb {
  border-radius: 4px;
  background: rgba(33, 42, 57, 0.1);
  padding: 10px 1rem;
}

.track_lead_bread {
  overflow: hidden;
  color: #055646;
  text-overflow: ellipsis;
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.partnerName {
  overflow: hidden;
  color: rgba(33, 42, 57, 0.75);
  text-overflow: ellipsis;
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
}

.stats {
  display: flex;
}

.stats > div {
  border-radius: 16px;
  border: 1px solid rgba(3, 86, 66, 0.5);
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(3, 86, 66, 0.25);
  margin: 1rem;
  padding: 1rem;
  width: 15%;
}

.stats_head {
  color: #3c4852;
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: -0.266px;
}

.stats_stat {
  color: var(--097252, #097252);
  font-family: "Source Sans 3";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 48px */
  letter-spacing: -0.608px;
}

.light_green {
  background-color: #53a451;
}

.white_green {
  border-radius: 4px;
  border: 1px solid;
  border-color: #53a451;
  background-color: #fff;
  color: #53a451;
}

.profile_form {
  border: 1px solid #00000024;
  margin: 1rem 0rem;
  background-color: #fff;
  border-radius: 8px;
}

.action-icons {
  display: flex;
}

.action-icons > img {
  cursor: pointer;
  margin: 0.3rem;
}

.flaghistory_div_main {
  padding: 1rem;
}

.fh_head {
  color: #212a39;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.flaghistory_div {
  display: flex;
}

.fh_1 {
  width: 20%;
  display: flex;
  flex-direction: column;
  color: #212a39;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.fh_2 {
  width: 80%;
}

.fh_3 {
  margin: 0.1rem;
}

.errBox_container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  background: rgba(60, 72, 82, 0.75);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errBox_container_box {
  width: 15vw;
  padding: 2rem;
  border-radius: 8px;
  background-color: #fff;
}

.errBox_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errBox_Head {
  color: #212a39;
  text-align: center;
  font-family: "Source Sans 3";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.errBox_Body {
  text-align: center;
  margin-top: 1rem;
  color: #212a39;
  text-align: center;
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.errBox_btn {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.err_no,
.err_yes {
  border-radius: 4px;
  opacity: 0.75;
  background: #dc3545;
  width: 74px;
  height: 32px;
  border: none;
  cursor: pointer;
  color: #fff;
}

.err_yes {
  background: #014644;
}

.link {
  color: blue;

  /* text-decoration: underline; */
  /* cursor: pointer; */
}

.inact {
  display: flex;
  justify-content: space-between;
}

.inact_btn {
  border-radius: 5px;
  background: #dc3545;
  color: #fff;
  border: none;
  padding: 1rem 0.5rem;
  cursor: pointer;
}

input:read-only {
  background-color: #efefef;
}

.resubmitApplication {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  border-radius: 8px;
}

.reapp {
  background-color: #1688a8;
  border-radius: 8px;
  padding: 0.7rem 0.5rem;
  color: #fff;
  border: none;
  cursor: pointer;
}

.f_headers {
  color: grey;

  font-size: 14px;
  font-weight: 600;
}

.f_remarks {
  border: 1px solid grey;
  border-radius: 8px;
  background-color: #efefef;
  padding: 1rem;
  min-height: 100px;
  color: grey;
}

.trb {
  border: solid 1px #00000037;
  width: 100px;
}

.table_head {
  background-color: #eeeeef;
  border: solid 1px;
}

.pending_yellow {
  background: #fdf2d0;
}

.pending_grey {
  background: #e2e4e5;
}

.flagged_red {
  background-color: #efcdcc;
}

.approve_green {
  background-color: #dce8d4;
}

.error-container {
  border: 1px solid transparent;
  border-color: #ebccd1;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin: 1rem;
}

.error-header {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.error-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  color: inherit;
}

.error-list {
  margin-top: 12px;
  margin: 2rem;
}

.error-tile {
  color: #a94442;
}

.has-error > input,
.has-error > select,
.has-error > .uploadPic {
  border: solid #b90e0b52;
}

.copyLink {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid rgba(33, 42, 57, 0.25);
  overflow: hidden;
  color: #212a39;

  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.w-copy-link {
  width: 18%;
}

.datalist > option {
  background-color: #fff;
  border: solid;
}

.MuiInputBase-input {
  max-height: 0.8rem;
}

.auth-box {
  padding-top: 15px;
}

.auth-ttl {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #212a39;
  margin-bottom: 8px;
}

.pass-container {
  display: block;
  width: 75%;
}

.auth-block .green {
  color: #035642;
}

.auth-block .right {
  float: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.auth-inp {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  padding-top: 25px;
  padding-bottom: 25px;
  background: rgba(33, 42, 57, 0.06);
  border: 1px solid rgba(33, 42, 57, 0.5);
  border-radius: 5px;
  font-size: 16px;
  border: none;
  line-height: 20px;
  outline: none;
  box-shadow: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.auth-inp:focus {
  outline: none;
  box-shadow: none;
  background: rgba(33, 42, 57, 0.06);
}

.password-strength {
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.password-strength .none {
  background: #d9d9d9;
  color: rgba(33, 42, 57, 0.5);
}

.password-strength .weak {
  background: #ff0c0c;
  color: #ff0c0c;
}

.password-strength .moderate {
  background: #f2d059;
  color: #f2d059;
}

.password-strength .strong {
  background: #00a400;
  color: #00a400;
}

.strength-dash {
  border-radius: 8px;
  height: 8px;
  width: 23%;
  margin-right: 2%;
}

.check-pass {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(33, 42, 57, 0.75);
  margin-bottom: 8px;
}

.check-pass img {
  margin-right: 16px;
  height: 1.25rem;
}

.strength-text {
  background: none !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 25%;
  float: right;
}

.auth-error {
  box-sizing: border-box;
  background: rgba(255, 12, 12, 0.1);
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px;
  color: #212a39;
  opacity: 0.75;
}

.auth-pass {
  display: block;
  background: rgba(33, 42, 57, 0.06);
  height: 50px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 16px;
  border: none;
  width: 100%;
}

.auth-pass:focus {
  border: none;
}

.show-btn {
  background: rgba(33, 42, 57, 0.06);
  padding: 15px;
  padding-left: 1px;
  padding-right: 1px;
  height: 51px;
  width: 25%;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212a39;
  opacity: 0.75;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}

.show-btn:hover {
  background: rgba(33, 42, 57, 0.2);
  cursor: pointer;
}

.show-btn img {
  margin-right: 4px;
}

.dark-green {
  background: #055646;
}

.expbtn {
  border: none;
  border-radius: 8px;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exp_prev {
  display: flex;
  justify-content: center;
  align-items: center;
}

.master_cards {
  display: flex;
}

.master_card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  padding: 1rem;
  cursor: pointer;
}

.master_head {
  text-align: center;
  margin-top: 1rem;
}

.master_pic {
  width: 80%;
  text-align: center;
  padding: 0.5rem;
  border-radius: 8px;
  background: rgba(22, 136, 168, 0.15);
}

.sg_users {
  background-color: #f0f0f0;
  margin: 0.3rem;
  padding: 0.3rem;
}

.sg_types {
  border: solid 0.5px #efefef;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 5rem;
  border-radius: 8px;
  width: 40%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.vrfy_btn {
  padding: 0.4rem 0.7rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #014644;
  color: #fff;
  font-size: 14px;
}

.bin {
  border: none;
  cursor: pointer;
  padding: 0.1rem;
  background-color: #dc3545;
  border-radius: 4px;
}

.editBTN {
  background-color: rgb(66, 66, 237);
  padding: 0.4rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error_aadhaar {
  width: 90%;
  text-align: center;
  color: red;
  background-color: rgba(255, 0, 0, 0.144);
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  padding: 0.4rem;
  border-radius: 4px;
}

.permissionMaping {
  border: solid 1px #efefef;
  width: 88%;
  padding: 1rem;
}

.pmHead {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.pmr2 {
  background-color: #efefef;
  padding: 0.3rem 0.6rem;
  border-radius: 8px;
}

.noteCreate {
  color: #3c4852bf;
  width: 90%;
  margin: auto;
}

.footOTP {
  margin-top: 1rem;
  text-align: center;
  color: #3c4852;
}

.infoBlock {
  padding: 1rem;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.infobtns {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
}

.uploadPartners {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 1rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.consultantDetails {
  padding: 1rem;
  margin-top: 2rem;
  border: solid 1px #efefef;
  border-radius: 8px;
}

.uploadAddBtns {
  display: flex;
}

.centeralign {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid;
}

.uploadAddBtn {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: #055646;
  color: #fff;
  cursor: pointer;
}

.darkbtn {
  background-color: rgba(60, 72, 82, 0.15);
  color: #000;
}

.pm_reject {
  color: #dc3545;
  border: solid 1px;
  background-color: #fff;
}

.remarks_bulk {
  padding: 1rem 2rem;
  width: 100%;
}

.overall_remarks {
  border: 1px solid rgba(33, 42, 57, 0.25);
  height: 100px;

  padding: 16px 24px;
  width: 90%;
}

.errorOTP {
  color: rgb(214, 5, 5);
}

.table_del {
  background-color: #dc3545;
}

.action-btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.topbar_name {
  max-width: 120px;
  width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.trb_fix_1 {
  position: sticky;
  left: 0px;
  background-color: #eeeeef;
}

.trb_fix_2 {
  position: sticky;
  left: 130px;
  background-color: #eeeeef;
  word-break: break-all;
}

.trb_fix_3 {
  position: sticky;
  left: 260px;
  background-color: #eeeeef;
  word-break: break-all;
}

.trb_fix_last {
  position: sticky;
  right: 0px;
  background-color: #eeeeef;
}

.act_btn {
  background-color: #055646;
}

.disable_btn:disabled {
  background-color: #0556467c;
  cursor: not-allowed;
}

.select_adm_head {
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 600;
}

.select_adm_select {
  width: 80%;
  height: 40px;
}

.upper_exp {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.top_btns_actions {
  display: flex;
  gap: 12px;
}

.back_btn_reverse {
  border: solid 1px;
  background-color: #fff;
  border-color: #035642;
  color: #035642;
}
